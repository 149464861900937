<template>
  <div class="bg-white">
    <div>
      <b-table
        ref="tablecustom"
        responsive
        striped
        hover
        :fields="fields"
        :items="formartedItems"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(first_name_th)="data">
          <router-link
            :to="`/report/customer/${data.item.user_guid}`"
            style="line-height: 15px"
          >
            <div class="text-bold">
              {{ data.item.first_name_th }} {{ data.item.last_name_th }}
            </div>

            <div class="f-12 text-light-black">{{ data.item.member_id }}</div>

            <div class="f-12 text-light-black">
              {{
                data.item.is_company == 0 || !data.item.is_company
                  ? "Personal"
                  : `Company  ${
                      data.item.company_information
                        ? data.item.company_information == "จดทะเบียน"
                          ? " : Register"
                          : ": Not Register"
                        : ""
                    }`
              }}
            </div>
          </router-link>
        </template>
        <template v-slot:cell(is_company)="data">
          {{
            data.item.is_company == 0 || !data.item.is_company
              ? "Personal"
              : `Company  ${
                  data.item.company_information
                    ? data.item.company_information == "จดทะเบียน"
                      ? " : Register"
                      : ": Not Register"
                    : ""
                }`
          }}
        </template>
        <template v-slot:cell(member_level_name)="data">
          <b-row>
            <b-col class="text-right p-0" cols="3">
              <img
                :src="data.item.member_level_icon"
                class="icon-level"
                @error="handleImageSrc"
              />
            </b-col>
            <b-col class="text-left" cols="9">
              {{ data.item.member_level_name }}
            </b-col>
          </b-row>
        </template>
        <template v-slot:cell(email)="data">
          <div class="text-left">
            {{ data.item.email ? data.item.email : "-" }}
          </div>
        </template>
        <template v-slot:cell(point_total)="data">
          <div>{{ data.item.point_total | numeral("0,0") }}</div>
        </template>
        <template v-slot:cell(grand_total)="data">
          <div>{{ data.item.grand_total | numeral("0,0.00") }}</div>
        </template>
        <template v-slot:cell(sale_name)="data">
          <div>
            <span v-if="data.item.sale_name">{{ data.item.sale_name }}</span>
            <span v-else>-</span>
          </div>
        </template>
        <template v-slot:cell(telephone)="data">
          <div class="d-flex align-items-center justify-content-center">
            {{ convertTelePhone(data.item.telephone) }}
            <template v-if="data.item.optional_telephone">
              <span
                class="fa-stack small"
                :id="'tooltip-target-' + data.item.user_guid"
                style="font-size: 0.6rem"
              >
                <font-awesome-icon icon="circle" class="fa-stack-2x" />
                <font-awesome-icon
                  icon="phone-alt"
                  class="fa-stack-1x text-white"
                />
              </span>

              <b-tooltip
                :target="'tooltip-target-' + data.item.user_guid"
                triggers="hover"
                custom-class="optional-container"
                placement="right"
              >
                Optional Telephone <br />
                <template
                  v-for="(
                    optional_telephone, index
                  ) of data.item.optional_telephone.split(',')"
                >
                  <div :key="optional_telephone + index">
                    {{ optional_telephone }}
                  </div>
                </template>
              </b-tooltip>
            </template>
          </div>
        </template>
        <template v-slot:cell(sale_channel_name)="data">
          <div>
            <span v-if="data.item.sale_channel_name"
              >{{ data.item.sale_channel_name }}
              {{
                data.item.sale_channel_name == "CRM" &&
                data.item.source_name == "MIM"
                  ? `(${data.item.source_name})`
                  : ""
              }}</span
            >
            <span v-else>-</span>
          </div>
        </template>
        <template v-slot:cell(created_time)="data">
          <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
          <div class="time-color">
            {{ data.item.created_time | moment("HH:mm:ss") }}
          </div>
        </template>
        <template v-slot:cell(is_consent)="data">
          <div
            :class="
              data.item.is_consent == 0
                ? 'text-danger'
                : data.item.is_consent == 1
                ? 'text-success'
                : 'text-warning'
            "
          >
            {{
              data.item.is_consent == 0
                ? "No"
                : data.item.is_consent == 1
                ? "Yes"
                : data.item.is_consent == 2
                ? "Wating For Approve"
                : data.item.is_consent == 3
                ? "Fail Consent 1"
                : data.item.is_consent == 4
                ? "Fail Consent 2"
                : data.item.is_consent == 5
                ? "Fail Consent 3"
                : data.item.is_consent == 6
                ? "Relieve"
                : "Suspend"
            }}
          </div>
        </template>
        <template v-slot:cell(action)="data">
          {{ data.item.action }}
          <b-button
            class="btn-see-all"
            @click.prevent="
              $router.push(`/report/customer/${data.item.user_guid}`)
            "
          >
            <div class="text-underline">Detail</div>
          </b-button>
        </template>
        <!-- Report Transaction -->
        <template v-slot:cell(invoice_no)="data">
          <div class="text-left title-transaction pointer">
            <router-link
              :to="`/report/transaction/${data.item.transaction_id}`"
              >{{
                data.item.invoice_no ? data.item.invoice_no : "-"
              }}</router-link
            >
          </div>
        </template>
        <template v-slot:cell(member_id)="data">
          <div class="text-left">
            <div
              class="title-transaction pointer"
              v-if="data.item.member_id"
              @click="$router.push(`/report/customer/${data.item.user_guid}`)"
            >
              {{ data.item.member_id }}
            </div>
            <div class="title-transaction" v-else>-</div>
            <div>
              {{ data.item.customer_name ? data.item.customer_name : "-" }}
            </div>
          </div>
        </template>
        <template v-slot:cell(status_name)="data">
          <div>
            <div v-if="data.item.deleted === 1" class="text-danger">
              Deleted
            </div>
            <div v-else-if="data.item.status_id === 1">
              <span class="text-success">Complete </span>
              <span class="status-void" v-if="data.item.is_void === 1"
                >(Void/Returned)</span
              >
            </div>
            <div
              v-else-if="data.item.status_id === 2 || data.item.status_id === 3"
              class="status-void"
            >
              Void/Returned
            </div>
            <div v-else-if="data.item.status_id === 4" class="text-danger">
              {{ data.item.status_name }}
            </div>
          </div>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
        <template #empty>
          <b-button
            @click="$emit('createUser')"
            variant="custom-outline-primary"
            >Create Customer</b-button
          >
        </template>
      </b-table>
    </div>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    showing: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    rows: {
      required: true,
      type: Number,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    filter: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      selectId: [],
      toShow: this.showingTo,
      showings: this.showing,
    };
  },
  computed: {
    formartedItems() {
      if (!this.items) return [];
      return this.items.map((item) => {
        item._rowVariant = item.deleted ? "danger border-top-unset" : "";
        return item;
      });
    },
  },
  methods: {
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showings = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.showings = value * this.filter.take - this.filter.take;
        this.toShow = this.showings + this.filter.take;
      }
      this.$emit("page", this.filter);
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.toShow = this.filter.take;
      this.$emit("handleChangeTake", this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.text-underline {
  text-decoration: underline;
}
.icon-level {
  height: auto;
  width: auto;
  max-width: 23px;
  max-height: 23px;
}
.color-name {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  padding: 5px 0;
  font-size: 20px;
  font-weight: 600;
}
.color-odd {
  background-color: var(--theme-secondary-color);
  color: var(--primary-color);
}
.color-even {
  background-color: #acd3ec;
  color: #1d9cee;
}
.btn-see-all {
  background-color: transparent;
  border: none;
}
.status-void {
  color: var(--primary-color);
}
.title-transaction {
  text-decoration: underline;
  color: #000;
}
.danger {
  background-color: #f8cfcf;
}
::v-deep .border-top-unset {
  td {
    border-color: #d8dbe0 !important;
  }
}
::v-deep .tooltip-inner {
  text-align: left;
}
</style>
